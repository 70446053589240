import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from '@norstella/nxp-sso-web/App'
import { Auth0ProviderWithNavigate } from '@norstella/nxp-sso-web/components/Auth0ProviderWithNavigate'
import { AppContextProvider } from '@norstella/nxp-sso-web/components/AppContextProvider'
import reportWebVitals from '@norstella/nxp-sso-web/reportWebVitals'

import './index.css'
import { AxiosInterceptor } from '@norstella/nxp-sso-web/components/AxiosInterceptor'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AppContextProvider>
          <AxiosInterceptor>
            <App/>
          </AxiosInterceptor>
        </AppContextProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals .
reportWebVitals()
